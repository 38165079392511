import type { IGroupProgressOptions, IGroupProgressJSON } from 'o365.pwa.modules.client.steps.GroupProgress.ts';
import { GroupProgress } from 'o365.pwa.modules.client.steps.GroupProgress.ts';

export interface ISystemLookupProgressJSON extends IGroupProgressJSON {
    systemLookupHasStarted: boolean;
    systemLookupHasErrors: boolean;
    systemLookupHasCompleted: boolean;
}

export interface ISystemLookupProgressOptions extends IGroupProgressOptions {
    systemLookupHasStarted?: boolean;
    systemLookupHasErrors?: boolean;
    systemLookupHasCompleted?: boolean;
}

export class SystemLookupProgress extends GroupProgress {
    private _systemLookupHasErrors: boolean = false;

    public systemLookupHasStarted: boolean = false;
    public systemLookupHasCompleted: boolean = false;

    public set systemLookupHasErrors(newValue: boolean) {
        this._systemLookupHasErrors = newValue;
    }

    public get systemLookupHasErrors(): boolean {
        return this.hasError || this._systemLookupHasErrors;
    }

    get progressStatusPending(): number {
        if (this.hasError) {
            return 0;
        }

        return this.systemLookupHasStarted ? 100 : 0;
    }

    get progressStatusSuccess(): number {
        if (this.hasError) {
            return 0;
        }

        return this.systemLookupHasCompleted ? 100 : 0;
    }

    get progressStatusError(): number {
        if (this.hasError) {
            return 100;
        }

        return this.systemLookupHasErrors ? 100 : 0;
    }

    constructor(options: ISystemLookupProgressOptions) {
        super(options);

        options.systemLookupHasStarted && (this.systemLookupHasStarted = options.systemLookupHasStarted);
        options.systemLookupHasErrors && (this._systemLookupHasErrors = options.systemLookupHasErrors);
        options.systemLookupHasCompleted && (this.systemLookupHasCompleted = options.systemLookupHasCompleted);
    }

    public toJSON(): ISystemLookupProgressJSON {
        return Object.assign(super.toJSON(), this);
    }
}
